import Player from '@vimeo/player'
import Component from './component'

export default class VideoBackground extends Component {
    constructor( props ) {
        super( props )
        this._header            = null
        this._wrapper           = null
        this._delay             = 5000
        this._locomotivepage    = null
        this._lazyloading       = false
        this.__to               = null
    }

    // Like jquery's $() ready function
    componentDidMount() {
        const self = this;
        const el = this.state.element;

        if( !el ) { throw "This component requires a valid DOM element." }


        self._header            = el.closest('.uielementalpageheader')
        self._wrapper           = el.closest('.videobg--container')
        self._locomotivepage    = !!([...document.querySelectorAll('main[data-scroll-container]')].length)
        self._lazyloading       = el.matches('[loading="lazy"]')

        self.__to = setTimeout(() => {
            self._wrapper.classList.add('fadein')
            if(self._header instanceof HTMLElement) {
                self._header.classList.add('videoready')
            }

            if(!self._locomotivepage) {
                const player = new Player(el)
                player?.play()
            }

            clearTimeout(self.__to)
        }, self._delay);
    }
}
