import Component from './component'

export default class SmoothScroll extends Component {
    constructor( props ) {
        super( props )
    }

    componentDidMount() {
        const el    = this.state.element

        if( !el ) { throw "This component requires a valid DOM element." }

        el.addEventListener('click', (e) => {
            const target = e.target
            if(target.matches('[smoothscroll],.smoothscroll,.md-button--smoothscroll')) {
                const selector     = obj.prop('hash') || null
                const scrollTarget = document.querySelector(selector)

                if(!!scrollTarget && scrollTarget instanceof HTMLElement) {
                    const offset = scrollTarget?.offsetTop
                    if(!!offset) {
                        window.requestAnimationFrame(() => {
                            scroll({
                                top: offset,
                                behavior: 'smooth'
                            })
                        })
                    }
                    e.preventDefault()
                    e.stopImmediatePropagation()
                }
            }
        })
    }
}