import Utils from '../lib/utils'
import Component from './component'

export default class DeferredMedia extends Component {
    constructor( props ) {
        super( props )
        this._isIE11 = Utils.isIE()
    }

    componentDidMount() {
        const self = this
        const el = this.state.element
        const $ = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        const options   = {
            threshold: [.5,0,0,.5]
        }

        const mediaObserver = new IntersectionObserver(( entries ) => {
            entries.map((entry) => {
                let eventDetail = {
                    element: entry.target,
                    entry: entry,
                    intersecting: entry.isIntersecting
                }

                if (entry.isIntersecting) {

                    let objMedia = $( entry.target )
                    let src     = objMedia.data('deferredSrc')
                    let type    = objMedia.data('deferType')

                    self.loadMediaOffCanvas( src, ( result ) => {
                        if( null !== result ) {
                            window.requestAnimationFrame(() => {
                                switch( type ) {
                                    case 'background-image':
                                        objMedia.css({'background-image': "url('" + src + "')"})
                                        objMedia.removeClass('non-deferred')
                                        break
                                    case 'src':
                                    default:
                                        objMedia.prop('src', src)
                                        objMedia.one('load', (e) => {
                                            objMedia.removeClass('non-deferred')
                                        })
                                }

                                // Remove any loading-related classes from the media
                                objMedia.removeClass('uiloading')
                                objMedia.parent().removeClass('uiloading')

                            })
                        }

                        // Regardless of the result, stop observing
                        mediaObserver.unobserve( entry.target )
                    })
                }
            })
        }, options)

        $('[data-deferred-src]',el).map((k,v) => {
            mediaObserver.observe( v )
        })

        // DOM Mutation Observer: Catch media that is added dynamically
        const __obconfig        = { attributes: true, childList: true, subtree: true }
        const __mutationtypes   = ['childlist','subtree']
        const __types           = ['img', 'image','div','section','header']
        const __observer        = new MutationObserver( (mutations) => {

            mutations.map( (mutation) => {
                let added       = Array.prototype.slice.call( mutation.addedNodes )
                if( !!__mutationtypes.includes(mutation.type.toLowerCase()) ) {
                    if( !!added.length ) {
                        added.map((v) => {
                            if( 1 === v.nodeType ) {
                                let type = v.nodeName.toLowerCase()
                                let obj = $( v )

                                if( __types.includes(type) && obj.hasClass('non-deferred') ) {
                                    // Handle the immediate node that triggered the MutationObserver.
                                    // This condition will only be met by matching nodes.
                                    mediaObserver.observe( v )
                                }else{

                                    // Walk this node's DOM to find any child nodes that wouldn't
                                    // have triggered the MutationObserver (subtree). Iamges discovered
                                    // in this manner are then fed to the IntersectionObserver, which
                                    // is then immediately triggered.
                                    const treeWalker = self._isIE11 ? document.createTreeWalker(v, NodeFilter.SHOW_TEXT, null, false) : document.createTreeWalker(v)
                                    let currentNode = treeWalker.currentNode
                                    while (currentNode) {
                                        type = currentNode.nodeName.toLowerCase()
                                        obj = $(currentNode)
                                        if( __types.includes(type) && obj.hasClass('non-deferred') ) {
                                            mediaObserver.observe( currentNode )
                                        }
                                        currentNode = treeWalker.nextNode()
                                    }
                                }
                            }
                        })
                    }
                }

            } )

        })

        __observer.observe( document.body, __obconfig )
    }

    loadMediaOffCanvas( src, callback ) {
        if( !src ) {
            callback.apply(this,[])
            return false
        }

        var image = new Image()
        image.onload = () => {
            callback.apply(this,[image])
        }
        image.onerror = () => {
            console.log('Error loading image: ' + src)
            image = null
            callback.apply(this,[image])
        }
        image.src = src
    }

}