import Component from './component'

export default class Fab extends Component {
    constructor( props ) {
        super( props )
        this.__smoothScrollEnabled = false
        this.__sections = []
        this.__topTargetID = null
    }

    // Like jquery's $() ready function
    componentDidMount() {
        const self = this
        const el = this.state.element
        const app = self.state.app

        if( !el ) { throw "This component requires a valid DOM element." }

        el.addEventListener('click', (e) => {
            self.onClickHandler(e)
        }, true)

        app.subscribe('onSmoothScrollEnabled', (e) => {
            const d = {...e.detail}
            self.__smoothScrollEnabled = !!d.enabled
            self.__sections = d.sections
            self.__topTargetID = d.topID
        })
    }

    onClickHandler( e ) {
        e.preventDefault()
        const self      = this
        const app       = self.state.app
        const $         = self.state.$
        const offset    = 0

        window.requestAnimationFrame(() => {
            if(self.__smoothScrollEnabled && !!self.__sections?.length) {
                if(!!self.__topTargetID) {
                    app.dispatch('onScrollToSection', new CustomEvent('ScrollToSection', {
                        detail: {
                            id: self.__topTargetID
                        }
                    }))
                }
            }else{
                scroll({
                    top: offset,
                    behavior: 'smooth'
                })
            }
        })
    }
}