import Utils from '../lib/utils';
import Component from './component';

export default class BlogLoadMore extends Component {
    constructor( props ) {
        super( props );
        this._config        = null;
        this._page          = 0;
        this._endpoint      = null;
        this._postcontainer = null;
    }

    componentDidMount() {
        const self  = this;
        const el    = this.state.element;
        const $     = this.state.$;

        if( !el ) { throw "This component requires a valid DOM element."; }

        self._config = el.data('config');

        if( !self._config ) { return false; }

        // Options
        self._page      = self._config.page;

        // The target container for newly loaded posts
        let targetID = el.data('target');
        self._postcontainer = $(Utils.sprintf('[data-target-id="%s"]',targetID));

        el.on('click','a', (e) => {
            let obj = $( e.target );
            self._endpoint = obj.prop('href');

            self.setLoadingStatus( 'Loading...' );
            el.addClass('loadmore--loading');
            self.paginate( self._endpoint );

            return false;
        });

        // Module-scoped event handlers
        self.subscribe('onAfterFetch', (event) => {
            self.render( event.detail.data );
            if( !self._page ) {
                self.setLoadingStatus( 'Unable to load more.' );
            }
        });

        self.subscribe('onFetchError', (event) => {
            el.removeClass('loadmore--loading');
            self.setLoadingStatus( event.detail.error );
        });
    }

    /**
     * Pagination.
     *
     * @param {string} endpoint The endpoint from which to load new pages
     */
    paginate( endpoint ) {
        const self = this;
        let config      = {method: 'GET'};
        let request     = new Request( endpoint, config );

        // Fetch
        fetch( request ).then( (response) => {
            if (response.status >= 200 && response.status < 300) {
                return Promise.resolve(response)
            } else {
                return Promise.reject(new Error(response.statusText))
            }
        } ).then( response => response.json() ).then( (data) => {

            // Store a module-scoped reference
            self._page = data;

            // Raise an event with the response data
            self.dispatch('onBeforeFetch', new CustomEvent('BeforeFetch', {
                detail: data
            }));

            self.dispatch('onAfterFetch', new CustomEvent('AfterFetch', {
                detail: {
                    data: data
                }
            }));
        }).catch( error => {
            if( 'error' in window.console ) {
                console.error(error);
            }

            self.dispatch('onFetchError', new CustomEvent('FetchError', {
                detail: {
                    data: request,
                    error: error
                }
            }));
        });
    }

    /**
     * Render newly loaded story tiles.
     *
     * @param {object} data The response object containing a list of new story tiles
     */
    render( data ) {
        const self      = this;
        const el        = this.state.element;
        const $         = self.state.$;
        const target    = self._postcontainer.get(0);
        self.setLoadingStatus('Load More Spotlights');
        el.removeClass('loadmore--loading');
        if( !!target ) {
            window.requestAnimationFrame(() => {

                let current = [target.innerHTML, self.formatPosts( data.posts ) || null];
                target.innerHTML = current.join("\n");

                // Replace the loadmore control
                let component = $(data.more);
                if( !!component ) {
                    // Update the endpoint
                    self._endpoint = $('a',el).prop('href');
                    // Update the loadmore markup
                    el.get(0).innerHTML = ( !!component.get(0) ) ? component.get(0).innerHTML : '';
                }else{
                    el.get(0).innerHTML = '';
                }

            });
        }

    }

    formatPosts( posts ) {
        const self      = this;
        const $         = self.state.$;
        if( !!posts && !!posts.length ) {
            let formatted = [];
            let template = $('<griditem />');
            if( !!template ) {
                let objTemplate = $(template).clone(true);
                formatted = posts.map((p) => {
                    objTemplate.html(p);
                    return objTemplate.prop('outerHTML');
                });
            }
            return formatted.join("\n");
        }

        return null;
    }

    /**
     * Set a status message.
     *
     * @param {string} message A status message to display
     */
    setLoadingStatus( message ) {
        const self = this;
        const el = this.state.element;
        const $ = self.state.$;
        const label = $('.loadmore--label',el);
        const icon = $('.loadmore--icon',el);
        if( !!label.length ) {
            label.text(message);
        }
        if( !!icon.length ) {
            icon.text('cached');
        }
    }
}