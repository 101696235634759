import Component from './component'

export default class MassiveMenu extends Component {
    constructor( props ) {
        props.data = {...{
            instance: null,
            menugroup: null,
            trigger: null,
            mouseenterdelay: 128
        }}
        super( props )
        this._collection = {}
        this._container  = null
        this.__to        = null
        this.__toInterval   = this.state.data.mouseenterdelay

    }

    componentDidMount() {
        const self  = this
        const el    = this.state.element
        const $     = this.state.$
        const app   = this.state.app

        if( !el ) { throw "This component requires a valid DOM element." }

        self._collection.length = 0
        $('massivegroup',el).toArray().map((v,k) => {
            let group = $( v )
            let key = group.data('menugroup')
            if( !!key ) {
                self._collection[ key ] = group
                self._collection.length++
            }
        })

        // container
        self._container = $(el).closest('.massivemenu')

        // Hook window scroll
        $(window).on('scroll resize', self.throttle((e) => {
            self.dispose()
            self.toggle()
        },128))


        // Remove the menu if user clicks somewhere outside the instance
        $('body').on('click', (e) => {
            let obj = $(e.target)
            if( !(obj.is('massivemenu') || obj.closest('.massivemenu').length) ) {
                self.dispose()
                self.toggle()
            }
        })

        if( !!self._collection.length ) {
            app.subscribe('onNavItemInteract',( e ) => {
                let menugroup = e.detail.menugroup
                switch( e.detail.type ) {
                    case 'mouseleave':
                        // Cancel the intention
                        if(!!self.__to) {
                            clearTimeout(self.__to)
                        }
                        break
                    case 'mouseenter':
                        self.toggleInstance( menugroup, e.detail.element )
                        break
                    default:
                        // e.detail.originalEvent.preventDefault();
                        // e.detail.originalEvent.stopImmediatePropagation();
                }
            })
        }

        self.dispose()
    }

    toggleInstance( menugroup, trigger ) {
        const self  = this
        const app   = self.state.app
        const $     = self.state.$
        const c     = !!self._container?.length ? self._container.get(0) : null

        let instance = (menugroup in self._collection) ? (self._collection[ menugroup ]).get(0).cloneNode(true) : null
        let __to = null
        const rect = trigger.getBoundingClientRect()
        const p = {
            x: rect.x,
            y: rect.y,
            height: rect.height
        }

        if( !!instance && menugroup !== self.state.data.menugroup ) {
            self.__to = setTimeout(() => {
                window.requestAnimationFrame(() => {
                    let objInstance = $(instance)
                    self.dispose().append( objInstance )
                    self.toggle(true)

                    // Set the position
                    if(!!c) {
                        c.style.left = `${p.x}px`
                        c.style.top = `${p.y + p.height}px`
                    }

                    __to = setTimeout(() => {

                        instance.removeAttribute('inactive')
                        clearTimeout(__to)
                    },96)

                    self.state.data.instance   = instance,
                    self.state.data.menugroup  = menugroup,
                    self.state.data.trigger    = trigger

                    // Set the trigger state
                    if( !!trigger ) {
                        $(trigger).addClass('active').attr('aria-expanded',true)
                    }
                    clearTimeout(self.__to)
                })
            }, self.__toInterval)
        }else{
            self.dispose()
            self.toggle()
        }

        return self
    }

    toggle( activate ) {
        const self      = this
        const app       = self.state.app
        const el        = self.state.element
        const container = el.get(0)

        if( !!activate ) {
            container.setAttribute('active',true)

            // Toggle the navbar alt state
            app.dispatch('onAppbarToggleCompact', new CustomEvent('AppbarToggleCompact', {
                detail: {
                    intersecting: !activate
                }
            }))

        }else{
            container.removeAttribute('active')
        }

        // Broadcast the menu's state
        app.dispatch('onMenuToggle', new CustomEvent('MenuToggle', {
            detail: {
                active:     !!activate,
                component:  self,
                current:    self.state.data
            }
        }))

        return el
    }

    dispose() {
        const self      = this
        const $         = self.state.$
        const el        = self.state.element
        const trigger   = self.state.data.trigger
        el.get(0).innerHTML = ''

        if( !!trigger ) {
            $(trigger).removeClass('active').attr('aria-expanded',false)
        }

        self.state.data.instance    = null
        self.state.data.menugroup   = null
        self.state.data.trigger     = null
        return el
    }

    getCurrent() {
        const self = this
        return {
            instance:   self.state.data.current,
            menugroup:  self.state.data.menugroup,
            trigger:    self.state.data.trigger
        }
    }

    // ---------------------------------------------------------- //
    // HELPERS
    // ---------------------------------------------------------- //
    throttle(func, delay) {
        let timeout = null
        return function (...args) {
            if (!timeout) {
                timeout = setTimeout(() => {
                    func.call(this, ...args)
                    timeout = null
                }, delay)
            }
        }
    }
}