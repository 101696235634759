import Utils from '../lib/utils'
import DOMNode from '../modules/domnode'
import DOMModel from '../modules/dommodel'
import Component from './component'
import Glider from 'glider-js'

'use strict'
export default class Gallery extends Component {
    constructor( props ) {
        super( props )
        this.__mouseMoveInterval = null
        this.__pause = null

        this.__tick = null
        this.__tickLast = null
        this.__tickStop = false
        this.__startTime = null
        this.__frameCount = 0

        this.__autoplayNext = 0

        this._config = null
        this._assets = null
        this._dialogInstance = null
        this._gliderInstance = null

        this.__datamodel = null
        this.__nodes = null

        this._root = null
        this._body = null
        this._progress = null
    }

    componentDidMount() {
        const self      = this
        const app       = this.state.app
        const el        = this.state.element
        const $         = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        self._root = document.querySelector('html')
        self._body = document.querySelector('body')

        console.log(el.getAttribute('layout'));
        if(el.getAttribute('layout') == 'scatter') {
            const items = [...el.querySelectorAll('griditem') ?? []]
            items.map(v => {
                const i = Utils.rand(1, 12) ?? 1
                const interval = setInterval(() => {
                    v.setAttribute('rs-layout', Utils.rand(1,24))
                    clearInterval(interval)
                }, (256 * i));
            })
        }

        if( el.hasAttribute('interactive') ) {
            const id            = el.getAttribute('id')
            const pagination    = [...document.querySelectorAll(`[rs-key="${id}"]`)]?.shift() ?? null
            const config        = JSON.parse(el.getAttribute('config') ?? '{}') ?? {}
            self._config        = new Map(Object.entries(config.options))

            if(!(self._config.has('dots'))) {
                self._config.set('dots', pagination)
            }

            if(!(self._config.has('loop'))) {
                self._config.set('loop', false)
            }

            if(!(self._config.has('controls'))) {
                self._config.set('controls', false)
            }

            self._config.set('rewind', !!self._config.has('loop'))

            // Event for state changes
            self.subscribe('onStateChange', (e) => {
                switch(e.detail.key) {
                    case 'progress':
                        self.updateScrollProgress()
                        break
                    default:
                        // console.log(e.detail);
                        break;
                }
            })

            self._assets = new Map()
            config?.gallery.map( v => self._assets.set(v.id, v))

            el.removeAttribute('config')
            el.addEventListener('click', (e) => {
                const target = e.target
                console.log(target);
                if ('img' === target.nodeName.toLowerCase()) {
                    const id = parseInt(target.getAttribute('asset-id'), 10)
                    if (self._assets.has(id)) {
                        self.showGallery(id)
                    }
                }
            })

            self.subscribe('onTick', (e) => {
                const d = e.detail
                if(!!self._config.get('autoplay')) {
                    const gi = self._gliderInstance
                    if(gi && gi instanceof Glider) {
                        // Update the progress bar
                        self.state.progress = (d?.p ?? 0).toFixed(2)
                    }
                }
            })

            self.subscribe('onTickInterval', (e) => {
                const d     = e.detail
                const gi    = self._gliderInstance
                if(gi && gi instanceof Glider) {
                    if(!!self._config.get('autoplay')) {
                        self.autoplay(gi)
                    }
                    self.updateGliderMeta()
                }
            })
        }
    }

    /**
     * Show the gallery UI
     *
     * @param {number} id The starting slide by ID. Starts at 0 if no ID is supplied
     */
    showGallery(id) {
        const self          = this
        const el            = self.state.el
        const $             = self.state.$
        const slides        = []

        // Reset everything
        // self.hide()

        self._assets.forEach(v => {
            slides.push(`<slide id="slide-${v.id}" asset-id="${v.id}" style="background: ${v.background}">${v.src}</slide>`)
        })

        const uuid = Utils.uuid()
        const dialog = $(
            `<dialog carousel fullscreen id="gallery-${uuid}" aria-labelledby="${uuid}_title" aria-describedby="${uuid}_description" class="dialog dialog--root">
                <dialogheading class="dialog-heading">{{title}}</dialogheading>
                <dialogcontent class="dialog-content"><glider ${self._config.get('effect') ?? 'default'}>${slides.join("\n")}</glider></dialogcontent>
                <button class="dialog-close"><icon class="md-icon">close</icon></button>
            </dialog>`
        )

        // Add a progress bar if autoplay is enabled
        if (!!self._config.get('autoplay')) {
            dialog.append($(
                `<div class="progress-container">
                    <progress max="100" value="0" class="progress progress--root"></progress>
                </div>`
            ))
        }

        // Add UI controls
        if (!!self._config.get('controls') && !self._config.get('autoplay')) {
            dialog.append($(
                `<controls>
                    <button prev aria-label="Previous" class="glider-prev">
                        <span label>
                            <slidestats>
                                <stat current>{{index}}</stat>
                                <stat total>{{totalslides}}</stat>
                            </slidestats>
                            <icon class="md-icon">chevron_left</icon>
                        </span>
                    </button>
                    <button next aria-label="Next" class="glider-next">
                        <span label>
                            <slidestats>
                                <stat current>{{index}}</stat>
                                <stat total>{{totalslides}}</stat>
                            </slidestats>
                            <icon class="md-icon">chevron_right</icon>
                        </span>
                    </button>
                </controls>`
            ))
            self._config.set('arrows', {
                prev: 'controls .glider-prev',
                next: 'controls .glider-next'
            })
        }

        // Add pagination controls
        if(!!self._config.get('autoplay') || !!self._config.get('controls')) {
            dialog.append($(
                `<pagination role="tablist" class="dots"></pagination>`
            ))
            self._config.set('dots', '.dots')
        }

        dialog.on('click', '.dialog-close', (e) => {
            e.stopImmediatePropagation()
            if(!!self._dialogInstance && self._dialogInstance instanceof HTMLElement) {
                self._dialogInstance.close()
            }
        })

        dialog.on('close', (e) => {
            self.hide()
        })

        // Track the new dialog
        self._dialogInstance = dialog.get(0)
        self._body?.prepend(self._dialogInstance)
        self.toggleNoScroll()

        // Show the modal
        self._dialogInstance.showModal()

        // Initialize the glider instance
        const g = dialog.get(0).querySelector('glider')

        g.addEventListener('glider-loaded', (e) => {
            const gi        = self._gliderInstance ?? Glider(e.target)
            const index     = self.getSlideIndexByID(id)
            if ((gi && gi instanceof Glider) && index) {
                gi.scrollItem(self.__autoplayNext = index)
            }
        })

        self._gliderInstance = new Glider(g, Object.fromEntries(self._config.entries()))

        // Databind nodes
        self.bindNodes(dialog.get(0))

        // Start the event tick only if autoplay is enabled
        if(self._config.get('autoplay')) {
            self.tick(true)
        }else{
            // Hook the scroll event when not autoplaying
            g.addEventListener('scroll', Utils.debounce((e) => {
                self.updateGliderMeta()
            }, 128))
        }
    }

    /**
     * Initialize one-way data-binding for all templated dialog elements
     *
     * @param {HTMLElement} instance Dialog instance
     */
    bindNodes(instance) {
        const self      = this
        const dialog    = instance
        if(instance && instance instanceof HTMLElement) {
            const controls      = dialog?.querySelector('controls')
            const heading       = [...dialog?.querySelectorAll('dialogheading') ?? []]
            const current       = [...controls?.querySelectorAll('slidestats stat[current]') ?? []]
            const total         = [...controls?.querySelectorAll('slidestats stat[total]') ?? []]

            self.__nodes        = new Set()
            self.__datamodel    = new DOMModel()
            self.__datamodel.title = `1 / ${self._assets.size} - Gallery`

            const nodes = [...current, ...heading, ...total]
            nodes?.map(v => {
                const n = new DOMNode(v)
                self.__nodes.add(n)
                self.__datamodel?.addCallback(() => {
                    n.update(self.__datamodel)
                })
            })

            // Force the intial update
            self.updateGliderMeta()
        }
    }

    /**
     * Get a slide index by its ID
     *
     * @param {number} id The ID of a given slide
     * @returns {number} Returns the resulting index if found, 0 if otherwise
     */
    getSlideIndexByID(id) {
        const self = this
        return [...self._assets.keys()].findIndex(a => a == id) ?? 0
    }

    /**
     * Get a slide object by index
     *
     * @param {number} index Slide index
     * @returns object|null Returns an object literal representing a slide
     */
    getSlideByIndex(index) {
        const self = this
        return [...self._assets.values()][index] ?? null
    }

    /**
     * Update all related slide meta
     */
    updateGliderMeta() {
        const self  = this
        const gi    = self._gliderInstance
        const index = gi?.slide
        const slide = self.getSlideByIndex(index)
        if(self.__datamodel) {
            self.__datamodel.title          = `${index + 1} / ${self._assets?.size} - ${slide?.title}`
            self.__datamodel.index          = (index + 1)
            self.__datamodel.totalslides    = self._assets?.size
        }
    }

    /**
     * Update the progress bar to reflect the amount of viewing time left for the current slide
     */
    updateScrollProgress() {
        const self = this
        const progress = self._progress ?? (self._progress = self._dialogInstance?.querySelector('progress'))
        const min = 0
        const max = 100
        const i = self.state.progress

        if(progress) {
            progress.setAttribute('value', Utils.clamp(i, min, max))
        }
    }

    /**
     * Autoplay the slides in a glider instance. This method supports looping.
     *
     * @param {Glider} instance An active Glider instance
     */
    autoplay(instance) {
        const self  = this
        const loop  = self._config.get('loop') ?? false
        const count = self._assets.size
        const slidesToScroll = instance?.opt?.slidesToScroll ?? 1
        const next = Utils.clamp(instance.slide + slidesToScroll, 0 , self._assets.size)
        self.__autoplayNext = (loop) ? ((next == count) ? 0 : next) : next

        if(!loop && (instance.slide == (count - slidesToScroll))) {
            self.__tickStop = true
        }
        instance.scrollItem(self.__autoplayNext)
    }

    /**
     * High-resolution time-based tick method
     *
     * @link https://jsfiddle.net/chicagogrooves/nRpVD/2/
     */
    tick() {
        const self  = this
        const fpsInterval   = self._config.get('autoplaydelay') ?? 5000

        self.__tickStop     = false
        self.__startTime    = window.performance.now()
        self.__frameCount   = 0

        let now     = Date.now()
        let elapsed = 0
        let then    = 0
        let p       = 0
        let fps     = 0

        self.__tick = (t) => {
            if(self.__tickStop) {
                return
            }

            if(self.__tick !== null) {
                requestAnimationFrame(self.__tick)
            }

            // Calc elapsed time, and percentage since last loop
            now     = t
            elapsed = now - then
            p       = (elapsed / fpsInterval) * 100

            // Debugging
            const sinceStart    = now - self.__startTime
            const currentFps    = fps = Math.round(1000 / (sinceStart / ++self.__frameCount) * 100) / 100
            const ms            = Math.round(sinceStart)
            const s             = Math.round(sinceStart / 1000 * 100) / 100
            const debug         = `Elapsed time= ${s} seconds (${ms}) @ ${currentFps} fps.`
            const evt = {
                p: p,
                fps: fps,
                elapsed: t,
                debug: debug
            }

            // Calculate the percentage of elapsed time
            self.dispatch('onTick', new CustomEvent('onTick', {
                detail: evt
            }))

            // console.log(`------------------------ ${evt.debug}`);
            // const f = fpsInterval / 1000
            // if((Math.round(s % f)) == 0) {
            //     console.log(s, f)
            // }

            if (elapsed > fpsInterval) {
                // Get ready for next frame by setting then=now, but also adjust for your
                // specified fpsInterval not being a multiple of RAF's interval (16.7ms)
                then = now - (elapsed % fpsInterval)

                // Reset %
                evt.p = p = 0

                // Raise an event to execute updates
                self.dispatch('onTickInterval', new CustomEvent('onTickInterval', {
                    detail: evt
                }))
            }
        }

        self.__tick()
    }

    hide() {
        const self = this

        self._gliderInstance?.destroy()
        self._gliderInstance = null

        self._dialogInstance?.remove()
        self._dialogInstance = null

        self.__pause = null
        self.__autoplayNext = 0

        self.__tickStop = true
        self.__tick = null
        cancelAnimationFrame(self.__tick)

        clearTimeout(self.__mouseMoveInterval)

        self.__datamodel = null
        self.__nodes = null

        self.toggleNoScroll()
    }

    /**
     * @link https://www.bennadel.com/blog/4442-using-a-transient-css-stylesheet-to-remove-scrolling-on-body-while-modal-is-open.htm
     */
    toggleNoScroll()
    {
        const self = this
        const root = self._root
        root.classList.toggle('md-no-scroll')
    }
}
