import LocomotiveScroll from 'locomotive-scroll'
import Component from "./component"
import Player from "@vimeo/player"
import Mutator from "../lib/mutator"
import Utils from '../lib/utils'

export default class LocomotivePage extends Component {

    constructor( props ) {
        super( props )
        this.__container = null
        this.__navitems = null
        this.__delay = 2
        this.__progressbar = null
        this.__players = {}
        this.__scrollThrottle = 10
        this.__headerAnimationDelay = 1800
    }

    componentDidMount() {
        const self      = this
        const app       = this.state.app
        const el        = this.state.element
        const $         = this.state.$
        const blocker   = app.getComponentByKey('UIBlocker')
        const mq        = window.matchMedia('(min-width: 1280px)')
        const loconav   = [...document.querySelectorAll('.locomotivenav')]
        const sections  = [...document.querySelectorAll('[data-scroll-section]')]
        const defaults  = {
            el: el,
            smooth: true,
            reloadOnContextChange: true
        }
        let options   = !!mq.matches ? defaults : {
            ...defaults,
            touchMultiplier: self.getScrollConfigValues('touchmultiplier', 'float') ?? 1.125,
            multiplier: self.getScrollConfigValues('multiplier', 'float') ?? 3.875,
        }
        if(!mq.matches && self.getScrollConfigValues('mobile', 'boolean')) {
            options = {
                ...options,
                smartphone: {
                    smooth: true
                }
            }
        }
        if(!mq.matches && self.getScrollConfigValues('tablet', 'boolean')) {
            options = {
                ...options,
                tablet: {
                    smooth: true
                }
            }
        }

        // Progress bar
        self.__progressbar = document.querySelector('.locomotiveprogress')

        if( !el ) { throw "This component requires a valid DOM element." }

        // Allow instance to be globally accessible
        const l = this.__container = new LocomotiveScroll(options)

        app.state.locomotive = l

        // Handle locomotive scroll event
        l.on('scroll', (args) => {
            self.updateScrollProgress(((args.scroll.y / args.limit.y) * 100) ?? 0)

            if(typeof args.currentElements === 'object') {
                const k = Object.values(args.currentElements)
                const s = new Set(k.filter((v) => {
                    const {section, id, progress} = v
                    return !!section ? (((v.progress >= 0.45) && (v.progress <= 0.55)) ? {'section' : section, 'id' : section.id, 'progress' : progress} : null) : false
                }).filter(b => !!b))
                const sections = [...s]

                if(!!sections.length) {
                    const obj = sections.shift()
                    self.dispatch('onSectionInView', new CustomEvent('SectionInView', {
                        detail: {
                            id: obj.section.id,
                            section: obj,
                            active: true
                        }
                    }))

                    app.dispatch('onAppbarToggleCompact', new CustomEvent('AppbarToggleCompact', {
                        detail: {
                            intersecting: (obj.section.el.nodeName.toLowerCase() == 'header')
                        }
                    }))
                }
            }
        })

        // Handle navigation instances
        if(!!loconav.length) {
            const logonavitems = self.__navitems = {}

            loconav.map( (v, k) => {
                const nav = $(v)
                nav.find('li[data-scrollid]').map((k, val) => {
                    logonavitems[val.dataset.scrollid] = val
                })
                nav.on('click', (e) => {
                    e.preventDefault()
                    const o = e.target
                    if(o.classList.contains('locomotivenav--link')) {
                        const u = new URL(o.href)
                        const t = !!u.hash ? document.querySelector(u.hash) : null
                        if(t) {
                            l.scrollTo(t)
                        }
                    }
                })
            })
        }

        // Subscribe to scroll related events
        self.subscribe('onSectionInView', (e) => {
            self.onSectionInViewHandler(e)
        })

        app.subscribe('onScrollToSection', (e) => {
            const id = e.detail.id
            const t = !!id ? document.querySelector(`#${id}`) : null
            if(t) {
                l.scrollTo(t)
            }
        })

        // Handle video playback (pause when out of view)
        app.subscribe('onSmoothScrollEnabled', (e) => {
            self.captureVideoPlayerInstances(e)
        })

        // Handle events when video players scroll into view
        self.subscribe('onPlayerInView', Utils.debounce((e) => {
            self.handleVideoPlayerStates(e)
        }, 128))

        // Broadcast locomotive instance
        app.dispatch('onSmoothScrollEnabled', new CustomEvent('SmoothScrollEnabled', {
            detail: {
                message: 'Locomotive Scroll enabled.',
                enabled: true,
                instance: l,
                sections: sections,
                topID: [...sections].shift()?.id
            }
        }))
    }

    /**
     * Parse scroll options from attributes on the data-scroll-container element
     *
     * @param {string} property
     * @param {*} type
     * @returns
     */
    getScrollConfigValues(property, type) {
        const self = this
        const el = self.state.element

        if(el.hasAttribute(property)) {
            const prop = el.getAttribute(property)
            switch(type) {
                case 'int':
                    return parseInt(prop, 10)
                case 'float':
                    return parseFloat(prop)
                case 'boolean':
                    return !!prop
            }
        }

        return null
    }

    /**
     * Update the progressbar element
     *
     * @param {float} p
     */
    updateScrollProgress(p) {
        const self = this
        const locoprogress = self.__progressbar
        const min = 0
        const max = 100
        const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

        if(locoprogress && p !== null) {
            locoprogress.setAttribute('value', clamp(p, min, max))
        }
    }

    /**
     * Handle sections as the enter and leave the viewport
     *
     * @param {CustomEvent} e
     */
    onSectionInViewHandler(e) {
        const self      = this
        const $         = this.state.$
        const l         = self.__container
        const id        = e.detail.id
        const items     = self.__navitems

        if(!!items && !!id && items.hasOwnProperty(id)) {
            const item = items[id]
            requestAnimationFrame(() => {
                Object.values(items).map((v, k) => {
                    v.removeAttribute('active')
                    v.removeAttribute('selected')
                })
                item.setAttribute('active', '')
                item.setAttribute('selected', '')
                self.expire(item)
            })
        }
    }

    /**
     * Reset the tooltip and indicator
     *
     * @param {HTMLElement} item
     */
    expire(item) {
        const self = this
        const delay = self.__delay
        const __to = null
        setTimeout(() => {
            item.removeAttribute('active')
            clearTimeout(__to)
        }, delay * 1000)
    }

    /**
     * Get a YouTube or Vimeo player instance (iframe)
     *
     * @param {string} type The type of player. Accepts 'YouTube' or 'Vimeo'
     * @param {string} id The ID of the player
     * @returns
     */
    getVideoPlayerInstance(type, id) {
        if(null === type || !(['youtube', 'vimeo'].includes(type ?? '')) || null === id) {
            return null;
        }
        const self = this
        const players = self.__players[ type ]
        const player = players.filter((v) => v.id === id)?.shift() ?? null
        return player
    }

    /**
     * Capture video player instances even when injected into the dom
     *
     * @param {CustomEvent} e
     */
    captureVideoPlayerInstances(e) {
        const self      = this
        const m = new Mutator({
            options: {
                attributes: false
            },
            watch: [
                {
                    selector: 'iframe',
                    callback: (e) => {
                        const t = e?.getAttribute('type')?.toLowerCase() ?? null
                        if(!!t && !!['vimeo', 'youtube'].includes(t)) {
                            self.setupVideoPlayerInstance({
                                id: e.getAttribute('id'),
                                type: t,
                                instance: e
                            })
                        }
                    }
                }
            ]
        })
    }

    /**
     * Set up video player instances
     *
     * @param {void} options
     */
    setupVideoPlayerInstance(options) {
        const self = this
        const e = options.instance
        let players = self.__players[ options.type ]
        if(!players) {
            players = self.__players[ options.type ] = []
        }

        // Track instances of
        e.setAttribute('src', e.getAttribute('data-src'))
        // Start intersection observer
        const settings   = {
            threshold: [0.0, 0.1, 0.2, 0.3, 0.4, 0.5, 0.6, 0.7, 0.8, 0.9, 1.0]//[0, 0.25, 0.5, 0.75, 1]
        }
        const observer = new IntersectionObserver(entries => {
            entries.map((entry) => {
                self.dispatch('onPlayerInView', new CustomEvent('PlayerInView', {
                    detail: {
                        id:             entry.target.getAttribute('id'),
                        type:           entry.target.getAttribute('type').toLowerCase(),
                        element:        entry,
                        container:      entry.target.closest('.uielementalcontainer'),
                        intersecting:   entry.isIntersecting,
                        ratio:          entry.intersectionRatio
                    }
                }))
              })
        }, settings)
        observer.observe(e)

        players.push({
            id: options.id,
            player: new Player(options.instance),
            observer: observer
        })
    }

    /**
     * Handle video player states when in-view
     * @param {CustomEvent} e
     */
    handleVideoPlayerStates(e) {
        const self = this
        const app = self.state.app
        const o = self.getVideoPlayerInstance(e.detail.type, e.detail.id)

        if(!!o && o.player instanceof Player) {
            if(e.detail.ratio >= 0.2) {
                o.player.play().then(() => {
                    if(app.state.mode == 'dev') {
                        console.log(`The video (${e.detail.id}) is playing...`)
                    }
                }).catch((error) => {
                    console.log(error)
                    switch (error.name) {
                      case 'PasswordError':
                          // The video is password-protected
                          break
                      case 'PrivacyError':
                          // The video is private
                          break
                      default:
                          // Some other error occurred
                          break
                    }
                })
                self.handleHeaderAnimation(e, true)
            }else{
                o.player.pause().then(() => {
                    if(app.state.mode == 'dev') {
                        console.log(`The video (${e.detail.id}) is paused...`)
                    }
                  }).catch((error) => {
                    console.log(error)
                    switch (error.name) {
                      case 'PasswordError':
                          // The video is password-protected
                          break
                      case 'PrivacyError':
                          // The video is private
                          break
                      default:
                          // Some other error occurred
                          break
                    }
                })
                self.handleHeaderAnimation(e)
            }
        }
    }

    handleHeaderAnimation(e, animate) {
        const self = this;
        const app = self.state.app
        const config = e.detail
        const section = config?.container ?? null
        const __to = null;

        if(section && section?.nodeName.toLowerCase() == 'header') {
            window.requestAnimationFrame(() => {
                switch(animate) {
                    case true:
                        setTimeout(() => {
                            section.classList.add('animation')
                            clearTimeout(__to)
                        }, self.__headerAnimationDelay);
                        break;
                    case false:
                    default:
                        // section.classList.remove('animate')
                        break;
                }
            })
        }
    }
}
