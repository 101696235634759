'use strict'

/**
 * Encapsulate a DOM Node instance to perform one-way data-binding.
 * Note: template strings use a simple syntax, e.g. "{{variable_name}}" in <span title="{{title}}">{{title}}</span>
 *
 * @link https://jack.ofspades.com/frameworkless-javascript-part-3-one-way-data-binding/index.html
 * @link https://jack.ofspades.com/frameworkless-javascript-part-2-templates-and-rendering/
 */
export default class DOMNode
{
    constructor(node) {
        this.node       = node
        this.template   = node?.innerHTML?.slice(0)
    }

    update(data) {
        if(!Object.getPrototypeOf(data).isPrototypeOf(Object)) {
            throw `Parameter must be of type Object. Invalid ${typeof(fn)} provided.`
        }
        const props = (data instanceof Map) ? data : new Map(Object.entries(data))
        const self  = this
        const temp  = self.template ?? null
        self.node.innerHTML = temp.replace(/\{\{\s?(\w+)\s?\}\}/g, (match, variable) => {
            return props.get(variable) ?? ''
        })
    }
}
