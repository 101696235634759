export default class Mutator {
    constructor( props ) {
        this.__objMutatorConfig    = {
            attributes: true,
            childList: true,
            subtree: true,
            ... props?.options ?? {}
        }
        this.__arMutatorAttributes = Object.keys( this.__objMutatorConfig ).map( item => item.toLowerCase() )
        this.__selectors = (('watch' in props) && Array.isArray(props?.watch)) ? new Map(props.watch.map(v => [v.selector, v.callback])) : null

        this.observe()
    }

    /**
     * Immediately trigger the callback to bind elements that already exist in the DOM
     * @returns
     */
    notify(root) {
        this.__selectors?.forEach( (callback, selector, map) => {
            const matches = [...(root ?? document).querySelectorAll(selector)]
            if(matches.length && (typeof callback == 'function')) {
                matches.map( m => {
                    callback(m)
                })
            }
        })
    }

    observe() {
        this.notify();

        // Return a MutationObserver for recurring instances
        return (new MutationObserver( (mutations) => {
            mutations.map((mutation) => {
                const added = [...mutation.addedNodes]
                if( !!this.__arMutatorAttributes.includes(mutation.type.toLowerCase()) && !!added.length ) {
                    added.map((node) => {
                        if(node instanceof HTMLElement && [1,2,3].includes(node.nodeType)) {
                            this.notify(node)
                        }
                    })
                }
            })
        })).observe( document.querySelector('html'), this.__objMutatorConfig )
    }
}
