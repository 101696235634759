import Component from './component'

export default class Analytics extends Component {
    constructor( props ) {
        super( props )
        this._dataLayer = []
        this._tmpData = {}
        const app = this.state.app
        this.state.gtmConfig = {...app.state?.gtm_events ?? {}}

    }

    componentDidMount() {
        const self  = this
        const app   = this.state.app
        const el    = this.state.element

        if( !el ) { throw "This component requires a valid DOM element." }

        /**
         * Store the submitted data.
         *
         * Don't push anything to window.dataLayer until
         * we know that the data was successfully submitted.
         */
        app.subscribe('onBeforeFormSubmit', (evt) => {
            // Store the submitted data.
            // Don't push anything to window.dataLayer until
            // we know that the data was successfully submitted.
            if( 'data' in evt.detail ) {
                let key = evt.key
                let data = evt.detail.data
                self._tmpData[ key ] = JSON.parse(data)
            }

        })

        app.subscribe('onAfterFormSubmit', (evt) => {
            if( 'data' in evt.detail ) {
                let key     = evt.key
                let frmData = self._tmpData[ key ]
                self.sendGAEvent(frmData, () => {
                    console.log('Event pushed')
                    self._tmpData[ key ] = null
                })
            }
        })
    }

    // ---------------------------------------------------------- //
    // GOOGLE ANALYTICS / DATALAYER SUPPORT
    // ---------------------------------------------------------- //
    sendGAEvent( props, callback ) {
        const self = this
        const app = self.state.app
        const template = {
            event: "formSubmission",
            eventCategory: null,
            eventAction: null,
            eventLabel: null,
            eventValue: null
        }

        // Merge template and props
        let frmProps = self.state.gtmConfig[props.SSFormID.replace('LeadForm_', '')]
        let obj = {...template,...frmProps}

        // console.group('sendGAEvent')
        // console.log('state', self.state.gtmConfig)
        // console.log('props.SSFormID', props.SSFormID)
        // console.log('template', template)
        // console.log('props', props)
        // console.log('frmProps', frmProps)
        // console.log('obj', obj)
        // console.groupEnd()

        if( callback ) {
            obj.eventCallback = callback
            obj.eventTimeout = 2000 // Prevent page timeouts...
        }

        if( !!('dataLayer' in window) ) {
            window.dataLayer.push(obj)
            if( app.state.mode == 'dev' ) {
                console.log('sendGAEvent::obj', obj, app.state.mode)
            }
        }else{
            // The datalayer isn't available right now... store locally to prevent script errors.
            self._dataLayer.push( obj )
        }
    }
}