
// CSS
import './scss/main.scss'

//JS Application
import $ from 'jquery'
import Umbra from './javascript/modules/umbra'
import Navigation from './javascript/components/navigation'
import MDForms from './javascript/components/mdforms'
import Forms from './javascript/components/forms'
import Fab from './javascript/components/fab'
import Appbar from './javascript/components/appbar'
import DeferredMedia from './javascript/components/deferredmedia'
import Carousel from './javascript/components/carousel'
import UIBlocker from './javascript/components/uiblocker'
import AccreditedImage from './javascript/components/accreditedimage'
import SmoothScroll from './javascript/components/smoothscroll'
import FormObserver from './javascript/components/formobserver'
import MassiveMenu from './javascript/components/massivemenu'
import Analytics from './javascript/components/analytics'
import LeadCTA from './javascript/components/leadcta'
import BlogLoadMore from './javascript/components/blogloadmore'
import Gallery from './javascript/components/gallery'
import AnimationObserver from './javascript/components/animationobserver'
import CookieConsent from './javascript/components/cookieconsent'
import Modal from './javascript/components/modal'
import LocomotivePage from './javascript/components/locomotivepage'
import Mutator from './javascript/lib/mutator'
import VideoBackground from './javascript/components/videobackground'
import Timeline from './javascript/components/timeline'

// Umbra singleton
const U = ('__umbrastate__' in window) ? new Umbra({state:{
    active:true,
    ...window['__umbrastate__']
}}) : new Umbra({state:{
    active:true
}})

// Ready callback (like jQuery's $())
U.ready(() => {
    U.subscribe('onComponentRegistered', () => {
        // console.log('onComponentRegistered',arguments)
    })

    U.subscribe('onStateChange', () => {
        // console.log('onStateChange',arguments)
    })

    const __m = new Mutator({
        options: {
            attributes: false
        },
        watch: [
            {
                selector: '.cookieconsent',
                callback: (e) => {
                    const objCookieConsent = new CookieConsent({app: U, element: e, $:$})
                    U.registerComponent(objCookieConsent.id, objCookieConsent)
                }
            },
            {
                selector: 'body',
                callback: (e) => {
                    const objAnalytics = new Analytics({app:U, element: e, $:$})
                    U.registerComponent( objAnalytics.id, objAnalytics )

                    const objNav = new Navigation({app:U, element: e, $:$})
                    U.registerComponent( objNav.id, objNav )

                    const objDeferred = new DeferredMedia({app:U, element: e, $:$})
                    U.registerComponent( objDeferred.id, objDeferred )

                    const objLeadCta = new LeadCTA({app:U, element: e, $:$})
                    U.registerComponent( objLeadCta.id, objLeadCta )

                    const objModals = new Modal({app:U, element: e, $:$})
                    U.registerComponent( objModals.id, objModals )

                    const objSmoothScroll = new SmoothScroll({app:U, element: e, $:$})
                    objSmoothScroll.componentDidMount()
                    U.registerComponent( objSmoothScroll.id, objSmoothScroll )

                    const objFormObserver = new FormObserver({app:U, element: e, $:$})
                    objFormObserver.componentDidMount()
                    U.registerComponent( objFormObserver.id, objFormObserver )
                }
            },
            {
                // Locomotive page
                selector: '[data-scroll-container]',
                callback: (e) => {
                    const objLocomotivePage = new LocomotivePage({app:U, element: e, $:$})
                    U.registerComponent( objLocomotivePage.id, objLocomotivePage )
                }
            },
            {
                selector: '.videobg[loading="lazy"],[videobg][loading="lazy"]',
                callback: (e) => {
                    const objVideoBG = new VideoBackground({app: U, element: e, $:$})
                    U.registerComponent(objVideoBG.id, objVideoBG)
                }
            },
            {

                selector: '.uielementalgallery:not([layout="cascade"])',
                callback: (e) => {
                    const objGallery = new Gallery({app:U, element:e, $:$})
                    U.registerComponent( objGallery.id, objGallery )
                }
            },
            {
                selector: 'massivemenu',
                callback: (e) => {
                    const objMassiveMenu = new MassiveMenu({app:U, element: e, $:$})
                    U.registerComponent( objMassiveMenu.id, objMassiveMenu )
                }
            },
            {
                selector: 'img[data-copyright], img[data-credit]',
                callback: (e) => {
                    const objAccreditedImage = new AccreditedImage({app:U, element: e, $:$})
                    objAccreditedImage.key = `AccreditedImage_${k}`
                    U.registerComponent( objAccreditedImage.id, objAccreditedImage, objAccreditedImage.key )
                }
            },
            {
                selector: 'loadmore,.loadmore',
                callback: (e) => {
                    const objBlogLoadMOre = new BlogLoadMore({app:U, element: e, $:$})
                    U.registerComponent( objBlogLoadMOre.id, objBlogLoadMOre )
                }
            },
            {
                selector: '.carousel',
                callback: (e) => {
                    let objCarousel = new Carousel({app:U, element: e, $:$})
                    U.registerComponent( objCarousel.id, objCarousel )
                }
            },
            {
                selector: 'form.md-form.md-form-enhanced',
                callback: (e) => {
                    const objEnhancedForm = new Forms({app:U, element: e, $:$})
                    objEnhancedForm.key = `Forms_${k}`
                    U.registerComponent( objEnhancedForm.id, objEnhancedForm, objEnhancedForm.key )
                }
            },
            {
                selector: 'form.md-form',
                callback: (e) => {
                    const objMDForm = new MDForms({app:U, element: e, $:$})
                    U.registerComponent( objMDForm.id, objMDForm )
                }
            },
            {
                selector: '.md-fab',
                callback: (e) => {
                    const objFab = new Fab({app:U, element: e, $:$})
                    U.registerComponent( objFab.id, objFab )
                }
            },
            {
                selector: '.md-appbar',
                callback: (e) => {
                    const objAppbar = new Appbar({app:U, element: e, $:$})
                    U.registerComponent( objAppbar.id, objAppbar )
                }
            },
            {
                selector: 'main uielementalcontainer, body > header, main header, main banner, footer, fab, cookieconsent',
                callback: (e) => {
                    let objAnimationObserver = new AnimationObserver({app:U, element: e, $:$})
                    U.registerComponent( objAnimationObserver.id, objAnimationObserver )
                }
            },
            {
                selector: '[rs-timeline]',
                callback: (e) => {
                    let objTimeline = new Timeline({app: U, element: e, $: $})
                    U.registerComponent( objTimeline.id, objTimeline )
                }
            }
        ]
    })

    // Recaptcha Initialization
    // if( 'grecaptcha' in window && !!grecaptcha ) {
    //     const sitekey = $('script[data-sitekey]').data('sitekey')
    //     grecaptcha.ready(function () {
    //         grecaptcha
    //             .execute(sitekey, {action: "validate_captcha"})
    //             .then(function (e) {
    //                 document
    //                     .querySelectorAll(".recaptchaResponse")
    //                     .forEach(c => c.value = e)
    //             })
    //     })
    // }
})
