import Utils from "../lib/utils"
import Component from "./component"

export default class AssetHandler extends Component {
    constructor( props ) {
        super( props )
        this._key       = 'AssetHandler'
        this._endpoint  = 'HandleAsset'
        this.state.maxFileSize  = 1024
        this.state.container    = null
        this.state.form         = null
        this.state.label        = null
        this.state.defaultLabel = null
        this.state.asset        = null
        this.state.fileReader   = new FileReader()

        this.componentDidMount()
    }

    componentDidMount() {
        const self      = this
        const app       = self.state.app
        const el        = this.state.element
        const $         = this.state.$
        const blocker   = app.getComponentByKey('UIBlocker')

        if( !el ) { throw "This component requires a valid DOM element." }

        // Field container
        const c = self.state.container = $(el).closest('.field.file')
        const f = self.state.form = $(el).closest('form')
        const lbl = self.state.label = c.find('.field-label .field-description--value')
        self.state.defaultLabel = !!lbl.length ? lbl.text() : ''
        const field = el.get(0)

        // Ready
        self.status('ready')

        // Track the max file size
        self.state.maxFileSize = f.find('input[name="MAX_FILE_SIZE"]').get(0).value ?? 1024

        // Trigger the file field
        c.on('click', (e) => {
            const n = !!e.target.nodeName ? e.target.nodeName.toLowerCase() : null
            if(n !== 'input' && !!field) {
                field.click()
            }
            e.stopImmediatePropagation()
        })

        // Change handler for file field
        el.on('change', (e) => {
            e.preventDefault()
            e.stopImmediatePropagation()
            const file      = self.state.file = field.files[0]
            const reader    = self.state.fileReader

            if(!file && !file.size) return false

            if(self.validateFileSize(file.size, self.state.maxFileSize)) {
                // Handle image uploads?
                // reader.addEventListener('load', function(e){
                //     let src = reader.result
                //     self.dispatch('onFileChange', new CustomEvent('FileChange', {
                //         detail: {
                //             message: file.name,
                //             file: file,
                //             type: e.type,
                //         }
                //     }))
                // }, true)

                // reader.readAsDataURL(file)

                self.dispatch('onFileChange', new CustomEvent('FileChange', {
                    detail: {
                        message: file.name,
                        file: file,
                        type: e.type,
                    }
                }))
            }else{
                app.dispatch('onNotify', new CustomEvent('Notify', {
                    detail: {
                        message: Utils.sprintf("Please reduce the size of our image, or select one that is less than %s", self.humanFileSize( self.maxFileSize ) ),
                        type: 'error',
                    }
                }))
            }
        })

        app.subscribe('onFileUpload', (e) => {
            // console.log('onFileUpload', e, e.detail,  e.detail.message)
            switch(e.detail.type) {
                case 'uploading':
                case 'loading':
                case 'error':
                    self.status(e.detail.type, e.detail.message)
                    break
                case 'success':
                    self.status(e.detail.type, e.detail.message)
                    self._id = e.detail?.data?.ID ?? null
                    console.log(e.detail, e.detail.data.ID, self._id)
                    break
                default:
                    self.status('ready')
                    break

            }
        })

        self.subscribe('onFileChange', (e) => {
            switch(e.detail.type) {
                case 'change':
                case 'input':
                    self.status(e.detail.type, e.detail.message)
                    self.uploadRelatedAsset()
                    break
                default:
                    self.status()
                    break
            }
        })

        app.subscribe('onNotify', (e) => {
            self.onNotify(e)
        })

        // Utility blocking
        self.subscribe('onToggleDisableInput', (evt) => {
            const target  = $(evt.detail.target)
            const b     = !!evt.detail.active
            if(b) {
                blocker.block( target )
            }else{
                blocker.unblock( target )
            }
        })
    }

    toString() {
        return this._id
    }

    toJSON() {
        return this.toString()
    }

    status(s, m) {
        const self  = this
        const c     = self.state.container
        const lbl = self.state.label
        if(!!c.length) {
            c.get(0).setAttribute('status', s ?? 'ready')
        }
        if(!!lbl.length) {
            lbl.get(0).innerHTML = m ?? self.state.defaultLabel
        }
    }

    uploadRelatedAsset() {
        const self      = this
        const app       = self.state.app
        const el        = self.state.element
        const $         = self.state.$
        const file      = self.state.file

        if(!file) return false

        const endpoint    = Utils.sprintf("%s/%s", window.location.href.replace(/\/$/, ''), self._endpoint)
        const formData    = new FormData()
        formData.append('Asset', file)

        let options = {
            method: 'POST',
            body: formData
        }

        let request = new Request(endpoint, options)

        if( !!endpoint ) {
            app.dispatch('onFileUpload', new CustomEvent('FileUpload', {
                detail: {
                    message: 'Upload in progress',
                    type: 'uploading'
                }
            }))

            self.dispatch('onToggleDisableInput', new CustomEvent('ToggleDisableInput', {
                detail: {
                    target: self.state.container,
                    active: true
                }
            }))

            fetch( request ).then( (response) => {
                if (response.status >= 200 && response.status < 300) {
                    return Promise.resolve(response)
                } else {
                    return Promise.reject(new Error(response.statusText))
                }
            } ).then( response => response.json() ).then( (data) => {
                // System notification
                app.dispatch('onFileUpload', new CustomEvent('FileUpload', {
                    detail: {
                        message: data.message || 'Operation completed',
                        data: data,
                        type: 'success'
                    }
                }))

            }).catch( error => {
                console.log('error', error)
                app.dispatch('onFileUpload', new CustomEvent('FileUpload', {
                    detail: {
                        message: 'Uh oh! An error occured!',
                        type: 'error'
                    }
                }))
            }).finally(() => {
                self.dispatch('onToggleDisableInput', new CustomEvent('ToggleDisableInput', {
                    detail: {
                        target: self.state.container,
                        active: false
                    }
                }))
            })
        }
    }

    humanFileSize(size) {
        if( !size || size == 0 ) return '0 MB'
        var i = Math.floor( Math.log(size) / Math.log(1024) )
        return ( size / Math.pow(1024, i) ).toFixed(2) * 1 + ' ' + ['B', 'kB', 'MB', 'GB', 'TB'][i]
    }

    validateFileSize( a, b ) {
        return a <= b
    }

    onNotify( e ) {
        console.log('onNotify', e, e.detail)
    }
}