import Component from './component';

/**
 * Example component to illustrate how to extend Component to build
 * reusable functionality followign ES6 patterns.
 */
export default class Navigation extends Component {
    constructor( props ) {
        super( props );
        this.state.range = {
            'x': 0.4,
            'y': 0.6
        }
        this.state.threshold = [0, 0.25, 0, 0.6, 1]
    }

    componentDidMount() {
        let self = this;
        let el = this.state.element;

        if( !el ) { throw "This component requires a valid DOM element."; }
        const app       = self.state.app;
        const $         = self.state.$;
        const objMain   = $('#Main',el);
        const objHeader = $('#Header, ampstorycontainer, header.uielementalcontainer, .rs__elemental__model__uielementalpageheader',el);
        const options   = {
            threshold: self.state.threshold//[0, 0.25, 0, 0.75, 1]//[0,0,0,.75]
        };
        const observer = new IntersectionObserver(entries => {
            entries.map((entry) => {
                let eventDetail = {
                    element: objHeader,
                    entry: entry,
                    intersecting: entry.isIntersecting
                };

                const range = self.state.range
                const r     = entry.intersectionRatio

                if( r >= range.x || r <= range.y ) {

                    app.dispatch('onAppbarToggleCompact', new CustomEvent('AppbarToggleCompact', {
                        detail: {...eventDetail,intersecting: !(r <= range.y)}
                    }));
                }

              });
        }, options);

        if( !!objHeader.length ) {
            observer.observe(objHeader.get(0));
        }

        app.subscribe('onMenuToggle',( e ) => {
            if( !!e.detail.active ) {
                const objTarget = $('searchform[floating]');
                objTarget.removeAttr('active');
            }
        });

        self.initSearchToggle();
    }

    initSearchToggle() {
        const self = this;
        const $ = self.state.$;
        const objTarget = $('searchform[floating]');

        if( !!objTarget.length ) {
            $('.searchtoggle').on('click', (e) => {
                let attr = objTarget.attr('active');
                if (typeof attr !== typeof undefined && attr !== false) {
                    objTarget.removeAttr('active');
                }else{
                    objTarget.attr('active',true);
                }
            });

            $('body').on('click', (e) => {
                let obj = $(e.target);

                if( !obj.is('searchform[floating]') && !obj.closest('searchform[floating]').length && !obj.is('.searchtoggle') ) {
                    objTarget.removeAttr('active');
                }
            });
        }
    }
}
