import Utils from '../lib/utils'
import md5 from '@leonismoe/md5'
import Component from './component'
import Mutator from "../lib/mutator"

export default class LeadCTA extends Component {
    constructor( props ) {
        super( props )
        this._key               = 'Forms_' + this._id
        this._categories        = []
        this._menubuttons       = []
        this._current           = null
        this._dialogInstance    = null
        this._formInstance      = null
        this._token             = null
        this._observer          = null
    }

    componentDidMount() {
        const self  = this
        const app   = self.state.app
        const el    = this.state.element
        const $     = this.state.$

        if( !el ) { throw "This component requires a valid DOM element." }

        self._categories = $('menucategory', el)
        self._menubuttons = $('.menubutton.menubutton--root', el)

        // Hook any CTAS for a booking modal
        $('body').on('click','a.leadcta:not(.cta--booking),button.leadcta:not(.cta--booking)', (e) => {
            e.preventDefault()
            let obj = $( e.target )
            let templateID = obj.prop('href')?.split('#').pop() ?? 'ModalFormContainer'
            if(!!templateID) {
                templateID = Utils.sprintf('#%s', templateID)
                let src = self.getCTAForm(templateID)
                if( !!src ) {
                    let formID = obj.data('formID') ?? src.querySelectorAll('input[name="SSFormID"]')[0]?.value ?? self._key ?? null
                    let config = {...obj.data('config')}
                    config.type = obj.data('type') ?? null

                    self.show(src, formID, config)
                }
            }
            return false
        })
    }

    getCTAForm(templateID) {
        const self = this
        const $ = self.state.$
        const id = templateID
        const template = $(id)
        const formTemplate = !!template.length ? template.get(0).content : null

        if( !!formTemplate && !!formTemplate.querySelectorAll('form').length ) {
            self._formInstance = formTemplate
        }
        return !!self._formInstance ?  self._formInstance.cloneNode(true) : null
    }

    show( src, formID, data ) {
        const self      = this
        const app       = self.state.app
        const $         = self.state.$
        const id        = formID
        const tmp       = $( '<div />' ).append( src )
        const form      = $('form', tmp)
        const ariaID    = form.prop('id')

        switch(data.type) {
            case 'contact':
            default:
                if(data.Title) {
                    $('h2', src).text(Utils.sprintf('Contact %s', data.Title))
                }
                if(data.UUID) {
                    let dd = $('select[name="UUID"]', src)
                    $('select[name="UUID"] option', src).removeAttr('selected')
                    $(`select[name="UUID"] option[value="${data.UUID}"]`, src).attr('selected', 'selected')
                    dd.closest('.field.dropdown').addClass('field--focus')
                }
                break
        }

        const ariaLabel = $(`#${ariaID}_Title`, form).text()
        const dialog = $(
            `<dialog id="${ariaID}-${id}" aria-labelledby="${ariaID}_Title" aria-describedby="${ariaID}_Content" class="uielementaldialog uielementaldialog--root">
                <dialogheading class="uielementaldialog-heading">${ariaLabel}</dialogheading>
                <dialogcontent class="uielementaldialog-content"></dialogcontent>
                <button class="uielementaldialog-close"><span class="md-icon">close</span></button>
            </dialog>`
        )

        // Modify the form if a formID was supplied
        if( !!id ) {
            const field = $('input[name="formID"]', form)

            // Append a formID field if we need it
            if( !field.length ) {
                const objformIDField = `<input type="hidden" name="formID" value="${formID}" class="hidden" id="${ariaID}_formID">`
                $('form',tmp).append( objformIDField )
            }
        }

        dialog.find('dialogcontent').append( $(tmp.html()) )
        dialog.on('click', '.uielementaldialog-close', (e) => {
            e.stopImmediatePropagation()
            self.hide()
        })

        // Remove existing dialogs
        self.hide()

        // Track the new dialog
        self._dialogInstance = dialog.get(0)

        $('html').addClass('md-no-scroll')
        $('body').append( dialog )

        self._observer = new Mutator({
            selector: 'dialog',
            callback: (e) => {
                window.requestAnimationFrame(() => {
                    e.forEach((v) => {
                        v.showModal()
                    })
                })
            }
        })
    }

    hide() {
        const self = this
        const $ = self.state.$
        $('html').removeClass('md-no-scroll')

        if(!!self._dialogInstance && self._dialogInstance instanceof HTMLElement) {
            self._dialogInstance.close()
            self._dialogInstance.remove()
        }

        self._dialogInstance = null
    }
}