export default class Dispatcher {
    constructor() {
        this.events = {};
    }

    /**
     * Either create a new event instance for passed `event` name
     * or push a new callback into the existing collection
     *
     * @param {string} event
     * @param {function} callback
     * @returns {number} A count of callbacks for this event
     * @memberof Dispatcher
     */
    subscribe(event, callback) {
        
        let self = this;
        
        if( !Object.prototype.hasOwnProperty.call( self.events,event) ) {
            self.events[event] = [];
        }
        
        return self.events[event].push(callback);
    }

    /**
     * If the passed event has callbacks attached to it, loop through each one
     * and call it
     *
     * @param {string} event
     * @param {object} [data={}]
     * @returns {array} The callbacks for this event, or an empty array if no event exits
     * @memberof Dispatcher
     */
    publish(event, data = {}) {
        let self = this;
        
        // There's no event to publish to, so bail out
        if(!Object.prototype.hasOwnProperty.call(self.events,event)) {
            return [];
        }
        
        // Get each subscription and call its callback with the passed data
        return self.events[event].map(callback => callback(data));
    }
}