import Utils from '../lib/utils';
import Component from './component';

export default class MDForms extends Component {
    constructor( props ) {
        super( props );
    }

    componentDidMount() {
        const self    = this;
        const el      = self.state.element;
        const $       = self.state.$;

        if( !el.length ) { throw "This component requires a valid DOM element."; }

        el.on('input focusin focusout select', 'input:not([type="hidden"]), textarea, select', (e) => {
            self.onInputHandler(e);
        });
    }

    onInputHandler( e ) {
        let $       = this.state.$;
        let obj     = $( e.target );
        let field   = obj.closest('.field');
        let wrapper = obj.closest('.field-wrapper');

        // console.log('e.type', e.type, obj.get(0).checkValidity());

        window.requestAnimationFrame(() => {
            if( !!obj.val() ) {
                field.addClass('field--active');
            }else{
                if( field.hasClass('field--active') ) {
                    field.removeClass('field--active');
                }
            }

            switch(e.type) {
                case 'focusin':
                    field.addClass('field--focus');
                    break;
                case 'focusout':
                    if( !obj.val() ) {
                        field.removeClass('field--focus');
                    }
                    break;
            }
        });
    }
}