'use strict'

/**
 * Provide a reusable model that can act as a key/value store wrapped in a Proxy instance.
 *
 * @link https://jack.ofspades.com/frameworkless-javascript-part-3-one-way-data-binding/index.html
 */
export default class DOMModel
{
    constructor(handlers) {
        const self = this
        this.callbacks = new Set()
        const data = {
            addCallback: (fn) => {
                if(typeof fn !== 'function') {
                    throw `Parameter must be of type Function. Invalid ${typeof(fn)} provided.`
                }
                this.callbacks.add(fn)
            }
        }

        this.properties = new Proxy(data, {
            set(target, prop, value) {
                Reflect.set(...arguments)
                self.callbacks?.forEach((callback) => {
                    callback()
                })
                return true
            }
        })

        return this.properties
    }
}
