import Component from './component'

export default class Appbar extends Component {
    constructor( props ) {
        super( props )
    }

    // Like jquery's $() ready function
    componentDidMount() {
        const self = this
        const el = this.state.element

        if( !el ) { throw "This component requires a valid DOM element." }

        const $             = self.state.$
        const app           = self.state.app
        self.state.html     = $('html')
        self.state.scrim    = $('.md-scrim')
        self.state.drawer   = $('.md-navigationdrawer')

        // Bind the navigation drawer
        const drawer        = self.state.drawer
        const scrim        = self.state.scrim
        if( !!drawer.length ) {

            drawer.on('click', (e) => {

                const o = $(e.target)

                // Handle the toggle
                if(o.get(0).classList.contains('md-drawernavlist--subnavtoggle')) {
                    e.preventDefault()
                    // Toggle the active state
                    const p = o.closest('.md-drawernavlist--item[subnav]')
                    let active = false
                    if(!!p.length) {
                        active = p.get(0)?.attributes?.hasOwnProperty('active')
                        if(!!active) {
                            p.get(0).removeAttribute('active','')
                        }else{
                            p.get(0).setAttribute('active','')
                        }
                    }
                }

                // Handle link clicks (pass through)
                if(o.get(0).classList.contains('md-drawernavlist--link')) {
                    self.dispatch('onNavDrawerToggle', new CustomEvent('NavDrawerToggle', {
                        detail: {
                            component: self,
                            originalEvent: e
                        }
                    }))
                }


            })

            // Toggle the drawer state
            drawer.on('click','.md-drawertoggle', (e) => {
                e.preventDefault()
                self.dispatch('onNavDrawerToggle', new CustomEvent('NavDrawerToggle', {
                    detail: {
                        component: self,
                        originalEvent: e
                    }
                }))
            })

            scrim.on('click', (e) => {
                const obj = $( e.target )
                if( obj.is('.md-scrim') ) {
                    self.dispatch('onNavDrawerToggle', new CustomEvent('NavDrawerToggle', {
                        detail: {
                            component: self,
                            originalEvent: e
                        }
                    }))
                }
            })

            $( el ).on('click', '.md-appbar--toggle', (e) => {
                self.dispatch('onAppbarToggle', new CustomEvent('AppbarToggle', {
                    detail: {
                        component: self,
                        originalEvent: e
                    }
                }))
            })

            // Nav Items
            $( el ).on('click mouseleave mouseenter', '.md-primarynav--link:not(.leadcta)', (e) => {
                e.stopImmediatePropagation()
                let obj = $( e.currentTarget )
                let menugroup = obj.data('menugroup')
                app.dispatch('onNavItemInteract',new CustomEvent('NavItemInteract', {
                    detail: {
                        component:      self,
                        type:           e.type,
                        menugroup:      menugroup,
                        originalEvent:  e,
                        element:        e.currentTarget
                    }
                }))
            })

            // Event subscriptions
            self.subscribe('onAppbarToggle', self.onAppbarToggleHandler)
            self.subscribe('onNavDrawerToggle', self.onNavDrawerToggleHandler)

            app.subscribe('onMenuToggle',( e ) => {
                $('.md-primarynav--link', el).removeClass('active')
            })
        }

        // Bind the primary navigation
        const navwrapper = $(el).find('navwrapper')
        const stateLabels = {
            active: 'close',
            inactive: 'menu',
        }

        if(!!navwrapper.length) {
            navwrapper.on('click', (e) => {
                const o = $(e.target).get(0)
                const nw = navwrapper.get(0)
                let __to = null;

                // Handle the toggle
                if(o.classList.contains('md-appbar--toggle')) {
                    e.preventDefault()
                    const icon = o.querySelector('.md-icon')
                    window.requestAnimationFrame(() => {
                        if(!nw.hasAttribute('active')) {
                            icon.innerText = stateLabels.active
                            nw.classList.remove('md-disabled')
                            __to = setTimeout(() => {
                                nw.setAttribute('active', '')
                                clearTimeout(__to)
                            }, 64);
                        }else{
                            icon.innerText = stateLabels.inactive
                            nw.removeAttribute('active')

                            __to = setTimeout(() => {
                                nw.classList.add('md-disabled')
                                clearTimeout(__to)
                            }, 512);
                        }
                    })
                }
            })
        }

        app.subscribe('onAppbarToggleCompact',( e ) => {
            self.onAppbarToggleCompactHandler( e )
        })

    }

    // ---------------------------------------------------------- //
    // EVENT HANDLERS
    // ---------------------------------------------------------- //
    /**
     * Handle the click event for the appbar toggle (hamburger menu item)
     *
     * @param {CustomEvent} e A custom event that wraps the original click event
     */
    onAppbarToggleHandler( e ) {
        const detail    = e.detail
        const event     = detail.originalEvent
        const self      = detail.component
        const scrim     = self.state.scrim
        const html      = self.state.html

        event.preventDefault()

        window.requestAnimationFrame(() => {
            html.addClass('md-no-scroll')
            scrim.addClass('md-scrim--active')
            self.onDrawerIn()
        })
    }

    onNavDrawerToggleHandler( e ) {
        const detail    = e.detail
        const self      = detail.component
        const drawer    = self.state.drawer
        const event     = detail.originalEvent

        drawer.removeClass('md-navigationdrawer--active')
        setTimeout(() => {
            self.onDrawerOut()
        }, 300)
    }

    onAppbarToggleCompactHandler( e ) {
        const self      = this
        const $         = self.state.$
        const appbar    = $( self.state.element )
        const detail    = e.detail
        appbar.toggleClass('md-appbar--compact', !detail.intersecting)
    }

    // ---------------------------------------------------------- //
    // METHODS
    // ---------------------------------------------------------- //
    /**
     * Trigger the drawer animate-in transition
     *
     * @param {Event} e An Event instance
     */
    onDrawerIn(e) {
        const self      = this
        const drawer    = self.state.drawer

        window.requestAnimationFrame(() => {
            drawer.addClass('md-navigationdrawer--active')
        })
    }

    /**
     * Trigger the drawer animate-out transition
     *
     * @param {Event} e An Event instance
     */
    onDrawerOut(e) {
        const self      = this
        const scrim     = self.state.scrim
        const html      = self.state.html

        window.requestAnimationFrame(() => {
            scrim.removeClass('md-scrim--active')
            html.removeClass('md-no-scroll')
        })
    }
}